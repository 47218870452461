/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    brandDetail: {},
  },
  mutations: {
    setBrandDetail(state, payload) {
      state.brandDetail = payload
    },
  },
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/money', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
