<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <!-- <b-button variant="primary" :to="{ name: 'brand_management_create' }"> 添加 </b-button> -->
        </b-col>

        <!-- Search -->
        <!-- <b-col cols="12" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <v-select v-model="user_id" :options="userOptions" :clearable="true" class="mr-1" style="min-width: 260px" label="real_name" :reduce="item => item.id" placeholder="请选择用户" @search="searchUser">
              <li slot="list-footer" class="pagination_vselest">
                <b-button :disabled="!hasPrevPage().value" @click.prevent="userOptionsPage--">上一页</b-button>
                <b-button :disabled="!hasNextPage().value" @click.prevent="userOptionsPage++">下一页</b-button>
              </li>
            </v-select>
            <v-select v-model="order_id" :options="ordersList" :clearable="false" class="mr-1" style="min-width: 260px" label="real_name" :reduce="item => item.id" placeholder="请输入订单" @search="searchOrders">
              <li slot="list-footer" class="pagination_vselest">
                <b-button :disabled="!hasPrevPageOrders" @click.prevent="ordersListPage--">上一页</b-button>
                <b-button :disabled="!hasNextPageOrders" @click.prevent="ordersListPage++">下一页</b-button>
              </li>
            </v-select>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      striped
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="暂无数据"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      bordered
      :busy.sync="isLoading"
    >
      <!--   条目ID   -->
      <template #cell(id)="data">
        <b-link class="font-weight-bold"> #{{ data.value }} </b-link>
      </template>

      <!--    品牌网址  -->
      <template #cell(url)="data">
        <b-link target="_blank" class="font-weight-bold" :href="data.value | filisHttp"> #{{ data.value }} </b-link>
      </template>

      <!--   状态   -->
      <template #cell(status)="data">
        <b-badge v-if="data.value == 0" variant="warning"> 待入账 </b-badge>
        <b-badge v-else-if="data.value == 1" variant="success"> 已入账 </b-badge>
        <!--        <b-badge v-else variant="success"> 已生效 </b-badge>-->
      </template>

      <!-- <template #cell(actions)="data">
        <b-button variant="primary" size="sm" :to="{ path: '/general/product/brand_management/edit', query: { id: data.item.id } }" @click.prevent="setBrandDetail(data.item)">编辑 </b-button>
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <b-button variant="danger" size="sm">删除</b-button>
          </template>
          <b-dropdown-item>
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span @click="confirmDelete(data.item.id)">确认删除</span>
          </b-dropdown-item>
        </b-dropdown>
      </template> -->
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">显示 {{ dataMeta.from }} 到 {{ dataMeta.to }} 条 {{ dataMeta.of }} 数据</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, getCurrentInstance, reactive, onMounted, computed } from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios'
import useInvoicesList from './CommissionList'

import BrandManagerModule from './CommissionModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    vSelect,
    BBadge,
    BLink,
  },
  filters: {
    filisHttp(value) {
      return value.indexOf('http') > -1 ? value : `http://${value}`
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'commission-module'
    const { proxy } = getCurrentInstance()
    //
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, BrandManagerModule)

    const userOptions = reactive([])
    const userOptionsPage = ref(1)
    const userOptionTotal = ref(0)

    const hasPrevPage = () => computed(() => userOptionsPage.value > 1)
    // ()
    const hasNextPage = () => computed(() => userOptionsPage.value * 20 < userOptionTotal.value)

    const searchUser = (search, loading) => {
      loading(true)
      axiosIns('/user/list', {
        params: {
          page: userOptionsPage.value,
          pageSize: 20,
          name: search,
        },
      }).then(res => {
        userOptions.splice(0, userOptions.length, ...res.data.list)
        userOptionTotal.value = res.data.count
        loading(false)
      })
    }

    // 订单列表
    const ordersList = reactive([])
    const ordersListPage = ref(1)
    const ordersListTotal = ref(0)

    const hasPrevPageOrders = () => computed(() => ordersListPage.value > 1)
    const hasNextPageOrders = () => computed(() => ordersListPage.value * 20 < ordersListTotal.value)
    const searchOrders = (search, loading) => {
      loading(true)
      axiosIns('/order/list', {
        params: {
          page: ordersListPage.value,
          pageSize: 20,
          number: search,
        },
      }).then(res => {
        ordersList.splice(0, ordersList.length, ...res.data.list)
        ordersListTotal.value = res.data.count
        loading(false)
      })
    }

    const setBrandDetail = data => {
      store.commit(`${INVOICE_APP_STORE_MODULE_NAME}/setBrandDetail`, data)
    }

    onMounted(() => {
      axiosIns('/user/list', {
        params: {
          page: userOptionsPage.value,
          pageSize: 20,
        },
      }).then(res => {
        userOptions.splice(0, userOptions.length, ...res.data.list)
        userOptionTotal.value = res.data.count
      })

      axiosIns('/order/list', {
        params: {
          page: 1,
          pageSize: 20,
        },
      }).then(res => {
        ordersList.splice(0, ordersList.length, ...res.data.list)
        ordersListTotal.value = res.data.count
      })
    })

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refInvoiceListTable,

      refetchData,
      isLoading,
      confirmDelete,
      user_id,
      order_id,
    } = useInvoicesList(proxy)

    return {
      isLoading,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refInvoiceListTable,

      refetchData,

      setBrandDetail,
      confirmDelete,

      user_id,
      order_id,
      userOptions,
      userOptionsPage,
      userOptionTotal,
      hasPrevPage,
      hasNextPage,

      ordersList,
      ordersListPage,
      searchUser,
      searchOrders,
      hasPrevPageOrders,
      hasNextPageOrders,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
